import { useEffect } from 'react';
import { messaging } from './FirebaseContext';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

// const getToken = () => {
//   return new Promise((resolve, reject) => {
//     messaging.getToken({ vapidKey: publicKey })
//       .then(token => resolve(token))
//       .catch(error => {
//         console.log('An error occurred while retrieving token. ', error);
//         reject(error);
//       });
//
//   });
// };

const onMessageListener = () => {
  return new Promise((resolve) => {
    try {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    } catch (error) {
      console.error(error);
    }
  });
};

export default function NotificationContext({ children }) {

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {

    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {

        onMessageListener()
          .then((payload) => {

            let message = '';

            const parsed = JSON.parse(payload.notification.body);


            switch (parsed?.type) {

              case 'arrivals/order/new':
                message = `Commande de ${parsed?.username}`;
                break;

              default:
                message = payload.notification.body;

            }

            const notificationTitle = payload.notification.title;
            const notificationOptions = {
              body: message,
              icon: '/favicon/favicon.ico'
            };

            new Notification(notificationTitle, notificationOptions);

            enqueueSnackbar(message, {
              variant: 'success', action: () => {
                return (
                  <IconButton>
                    <Close />
                  </IconButton>
                );
              }
            });

          })
          .catch((err) => console.log('failed: ', err));

        console.log('Notification permission granted');
      }
    }).catch(error => {
      console.log('Notification permission failed');
      console.error(error);
    });

  }, [enqueueSnackbar]);

  return (
    <>
      {children}
    </>
  );
}