import {
  Box,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material';
import { ProductListHead, ProductListToolbar } from '../product-list';
import Scrollbar from '../../../Scrollbar';
import { fDateTime } from '../../../../utils/formatTime';
import Label from '../../../Label';
import { fCurrency } from '../../../../utils/formatNumber';
import SearchNotFound from '../../../SearchNotFound';
import { useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { filter } from 'lodash';
import DrawerContainer from '../../../DrawerContainer';
import useToggle from '../../../../hooks/useToggle';
import { ORDER_SATE_COLOR } from '../../../../constants';
import useCustomOrder from './../../../../hooks/useCustomOrder';
import OrderCustomDetail from './OrderCustomDetail';
import OrdersMoreOptionsClients from './OrdersMoreOptionsClients';

const TABLE_HEAD = [
  { id: 'orderNum', label: 'N° Commande', alignRight: false },
  { id: 'quantity', label: 'Quantité', alignRight: false },
  // { id: 'amount', label: 'montant', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'createdAt', label: 'Commandé le', alignRight: false },
  { id: '' }
];


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_product) => _product.username.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }

  return stabilizedThis.map((el) => el[0]);
}


const formatOrders = (list) => {
  return list.map(one => {

    const amount = one.cart.map(val => val.subtotal).reduce((previousValue, currentValue) => {
      return previousValue + currentValue;
    });

    const quantity = one.cart.map(val => val.quantity).reduce((previousValue, currentValue) => {
      return previousValue + currentValue;
    });

    return {
      ...one,
      amount,
      quantity,
      createdAt: one.createdAt.toDate()
    };
  });
};

export default function OrderCustomList() {
  const { orders: list, loading } = useCustomOrder();

  const orders = useMemo(() => formatOrders(list), [list]);

  const { open, handleOpen, handleClose } = useToggle();

  const [selectedOrder, setSelectedOrder] = useState(null);

  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState('');


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = orders.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleOpenDetails = (id) => {
    setSelectedOrder(orders.find(one => one.id === id))
    handleOpen();
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;

  const filteredProducts = applySortFilter(orders, getComparator(order, orderBy), filterName);

  const isProductNotFound = filteredProducts.length === 0;

  return (
    <>

        <ProductListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ProductListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={orders.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {
                  filteredProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, orderNum, quantity, createdAt, status } = row;

                    const isItemSelected = selected.includes(id);

                    const openDetail = () => handleOpenDetails(id);

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role='checkbox'
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding='checkbox'>
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                        </TableCell>

                        <TableCell onClick={openDetail} >{orderNum}</TableCell>
                        <TableCell onClick={openDetail} >{fCurrency(quantity)}</TableCell>
                        {/* <TableCell >{`${fCurrency(amount)} CFA`}</TableCell> */}
                        <TableCell onClick={openDetail} >
                          <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={ORDER_SATE_COLOR[status]}
                          >
                            {status}
                          </Label>

                        </TableCell>
                        <TableCell onClick={openDetail} >{fDateTime(createdAt)}</TableCell>
                        <TableCell align='right'>
                          <OrdersMoreOptionsClients id={id} state={status} onOpenDetails={handleOpenDetails} />
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                )}
              </TableBody>
              {loading  && (
                <TableBody>
                  <TableRow>
                    <TableCell align='center' colSpan={4}>
                      <Box sx={{ py: 3, justifySelf:"center" }}>
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              
              {!loading && isProductNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align='center' colSpan={6}>
                      <Box sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      <DrawerContainer zIndex open={open} onClose={handleClose}>
        <OrderCustomDetail order={selectedOrder} onClose={handleClose}/>
      </DrawerContainer>

    </>
  );
}