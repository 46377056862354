export const ORDER_STATE = {
  PENDING: 'En Attente',
  FORSAKEN: 'Annulé',
  ACCEPTED: 'Accepté'
};

export const ORDER_SATE_COLOR={
  [ORDER_STATE.ACCEPTED]:"success",
  [ORDER_STATE.FORSAKEN]:"error",
  [ORDER_STATE.PENDING]:"warning",
}