import useAuth from './useAuth';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from '../redux/store';

const roleSet = {
  isClient: true,
  isAdmin: false
};

export default function useAuthorization() {
  const { user: { id } } = useAuth();
  const [loading, setIsLoading] = useState(false);

  const getUser = useSelector(state => state.firestore.ordered.currentUser);

  const roles = useMemo(() => {
    if (!getUser) {
      return roleSet;
    } else {
      const [userData] = getUser;

      if (!userData?.role) {
        return roleSet;
      } else {
        return {
          isClient: userData?.role === 'client',
          isAdmin: userData?.role !== 'client'
        };
      }

    }
  }, [getUser]);

  useEffect(() => setIsLoading(!isLoaded(getUser)), [getUser]);

  useFirestoreConnect([
    {
      collection: 'users',
      doc: id,
      storeAs: 'currentUser'
    }
  ]);

  return { loading ,...roles};
}