import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import productReducer from './slices/product';
import orderReducer from './slices/order';
import categoriesReducer from './slices/category';
import {  firestoreReducer } from 'redux-firestore'
import configsReducer from './slices/configs'; // <- needed if using firestore

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  user: userReducer,
  product: persistReducer(productPersistConfig, productReducer),
  orders: orderReducer,
  categories: categoriesReducer,
  configs:configsReducer
});

export { rootPersistConfig, rootReducer };
