import { isEmpty, isObject } from 'lodash';

export const isNot = (value) => Boolean(value) === false;

export function isFile(element) {
  return element instanceof File;
}

export function isDummyImage(element) {
  return isObject(element) && isNot(isFile(element)) && ('preview' in element);
}

export const isNotEmpty = (value) => {
  return isEmpty(value) === false;
};


export const isFirebaseTimestamp = (timestamp) => {
  return isObject(timestamp) && ('toDate' in timestamp);
};