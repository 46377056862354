import { sum } from 'lodash';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Stack, Tooltip, Box, Badge, Button } from '@mui/material';
import MIconButton from './../../../@material-extend/MIconButton';
import MHidden from './../../../@material-extend/MHidden';
import { Icon } from '@iconify/react';
import useCollapseDrawer from './../../../../hooks/useCollapseDrawer';
import { useSelector } from 'src/redux/store';

import useAuth from './../../../../hooks/useAuth';
import { styled, alpha } from '@mui/material/styles';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { PATH_PAGE } from 'src/routes/paths';
import shoppingCartFill from '@iconify/icons-eva/shopping-cart-fill';
import AccountPopover from './../../../../layouts/dashboard/AccountPopover';
import { PATH_AUTH } from './../../../../routes/paths';
import SearchIShopBar from './../../../../layouts/dashboard/SearchIShopBar';
// ----------------------------------------------------------------------


const APPBAR_MOBILE = 48;
const APPBAR_DESKTOP = 50;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100%)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

ShopSearchBar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function ShopSearchBar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const { checkout } = useSelector((state) => state.product);
  const totalItems = sum(checkout.cart.map((item) => item.quantity));
  const { isAuthenticated } = useAuth();
  const {pathname} = useLocation();

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100%)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <SearchIShopBar />
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
        <Box sx={{mr: 1}}>
          <Tooltip title="Panier">
            <MIconButton
            sx={{mt: 0.5}}
              color="info"
              size="large"
              to={PATH_PAGE.checkout}
              component={RouterLink}
            >
            <Badge showZero badgeContent={totalItems} color="error" max={99}>
                <Icon icon={shoppingCartFill} width={24} height={24} />
            </Badge>
            </MIconButton>
          </Tooltip>
          </Box>
          {isAuthenticated ? 
            <AccountPopover client={true} />
            : <Button color="info" 
                    size="small"
                    variant="outlined" to={PATH_AUTH.login}  
                    state={{ prevPage: pathname}}
                    component={RouterLink}>
                Connexion / inscription
             </Button>
          }
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
