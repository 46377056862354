import { storage } from '../../contexts/FirebaseContext';
import { isFunction } from 'lodash';

export async function multipleFilesSave(files, callback = null) {
  try {

    let data = [];

    await Promise.all(files.map(async (file) => {
      let fileSnap = await storage.ref().child(`${file.name}`).put(file);
      let url = await fileSnap.ref.getDownloadURL();
      let _file_ = { name: file.name, url: url, type: file.type };
      data.push(_file_);
    }));

    isFunction(callback) && callback(data);

  } catch (error) {
    console.error(error);
  }
}