import Scrollbar from '../../../Scrollbar';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // Typography
} from '@mui/material';
// import { fCurrency } from '../../../../utils/formatNumber';
import { styled } from '@mui/material/styles';

// const RowResultStyle = styled(TableRow)(({ theme }) => ({
//   '& td': {
//     paddingTop: theme.spacing(1),
//     paddingBottom: theme.spacing(1)
//   }
// }));

const ThumbImgStyle = styled('img')(({ theme }) => ({
  width: 64,
  height: 64,
  objectFit: 'cover',
  margin: theme.spacing(0, 2),
  borderRadius: theme.shape.borderRadiusSm
}));

export default function OrderCustomDetails({ order, onClose }) {

  return (
    <Grid container>
      <Grid item xs={12}>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 200 }}>
            <Table>
              <TableHead
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  '& th': { backgroundColor: 'transparent' }
                }}
              >
                <TableRow>
                  <TableCell width={40}>Image</TableCell>
                  <TableCell width={100} align='left'>Produit</TableCell>
                  <TableCell width={40} align='left'>Qty</TableCell>
                  {/* <TableCell width={40} align='right'>Price unitaire</TableCell>
                  <TableCell align='right'>Sous Total</TableCell> */}
                </TableRow>
              </TableHead>

              <TableBody>
                {
                  order.cart.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                        }}
                      >
                        <TableCell>
                          <ThumbImgStyle alt={'...'} src={row.cover} />
                        </TableCell>
                        <TableCell align='left'>{row.name}</TableCell>
                        <TableCell align='left'>{row.quantity}</TableCell>
                        {/* <TableCell align='right'>{fCurrency(row.price)}</TableCell>
                        <TableCell align='right'>{fCurrency(row.subtotal)}</TableCell> */}
                      </TableRow>
                    )
                  )
                }
{/* 
                <RowResultStyle>
                  <TableCell colSpan={3} />
                  <TableCell align='right'>
                    <Typography variant='h6'>Total</Typography>
                  </TableCell>
                  <TableCell align='right' width={140}>
                    <Typography variant='h6'>{fCurrency(order.amount)}</Typography>
                  </TableCell>
                </RowResultStyle> */}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Grid>

    </Grid>
  );

}