import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'configs',
  initialState: {
    shop: {
      page: 1,
      scrollPosition: 0
    }
  },
  reducers: {
    saveShopListAxis: (state, action) => {
      state.shop.page = action.payload.page;
      state.shop.scrollPosition = action.payload.scrollPosition;
    },

  }
});

export const { saveShopListAxis } = slice.actions;

const configsReducer = slice.reducer;

export default configsReducer;