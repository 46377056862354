import { firestore } from '../../contexts/FirebaseContext';

import { createSlice } from '@reduxjs/toolkit';
import { isFunction } from 'lodash';




const slice = createSlice({
  name: 'orders',
  initialState: {
    list: [],
    loading: false,
    error: null,
    isInitialized: false,
    custom: {
      orders: [],
      loading: false,
      error: false,
    }
  },
  reducers: {

    startLoading: state => {
      state.loading = true;
      state.isInitialized = true;
    },

    startCustomLoading: state => {
      state.custom.loading = true;
    },

    hasError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      console.error(action.payload);
    },


    hasCustomError: (state, action) => {
      state.custom.loading = false;
      state.custom.error = action.payload;
      console.error(action.payload);
    },

    gotList: (state, action) => {
      state.loading = false;
      state.error = null;
      state.list = action.payload;
    },

    gotCustomList: (state, action) => {
      state.custom.loading = false;
      state.custom.error = null;
      state.custom.orders = action.payload;
    },

    updateOrderSate: (state, action) => {
      const { id, newState } = action.payload;
      state.list = state.list.map(one => {
        if (one.id !== id) return one;
        return { ...one, state: newState };
      });
    }

  }
});

export default slice.reducer;

export const selectOrders = state => state.orders;

export const { startLoading, gotList, hasError, updateOrderSate, startCustomLoading, hasCustomError, gotCustomList } = slice.actions;

//#region thunks

export function fetchOrders(callback = null) {
  return async (dispatch, getState) => {
    try {
      dispatch(startLoading());

      firestore.collection('arrival_command')
        .orderBy('createdAt', 'desc').onSnapshot(observer => {

          const { list } = selectOrders(getState());
          let data = [...list];

          observer.docChanges().forEach((docChange) => {
            const { type, doc } = docChange;
            switch (type) {
              case 'added':
                data.push({
                  id: doc.id,
                  ...doc.data()
                });
                break;
              case 'modified': {
                let indexof = data.indexOf(one => one.id === doc.id);
                data.splice(indexof, 1, {
                  id: doc.id,
                  ...doc.data()
                });
              }
                break;
              case 'removed':
                data = data.filter(one => one.id !== doc.id);
                break;

              default:
                break;
            }
          });

          dispatch(gotList(data));

        });

      // const result = (empty) ? [] : docs.map(one => ({ id: one.id, ...one.data() }));


      isFunction(callback) && callback();

    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function fetchCustomOrders(userId, callback = null) {
  return async (dispatch, getState) => {
    try {
      dispatch(startCustomLoading());

      const result = await firestore.collection('arrival_command').where('userId','==', userId)
        .orderBy('createdAt', 'desc').get();

        const data = [];
        result.docs.forEach((doc) => {
          data.push({id: doc.id, ...doc.data()});
        })

        
      dispatch(gotCustomList(data));


      isFunction(callback) && callback();

    } catch (error) {
      dispatch(hasCustomError(error));
    }
  };
}

export function updateOrderState(orderId, state, callback = null) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      await firestore.collection('arrival_command').doc(orderId)
        .set({ status:state }, { merge: true });

      isFunction(callback) && callback();

    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

//#endregion