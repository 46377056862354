import { useDispatch, useSelector } from '../redux/store';
import { selectOrders } from '../redux/slices/order';
import { fetchCustomOrders } from '../redux/slices/order';
import useAuth from './useAuth';
import { useEffect } from 'react';

export default function useCustomOrder() {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const { custom: {orders, loading }} = useSelector(selectOrders);

  useEffect(() =>{
    dispatch(fetchCustomOrders(user.id));
  },[dispatch, user.id])

  return { loading, orders };
}