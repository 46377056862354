import { createSlice } from '@reduxjs/toolkit';
import { firestore } from '../../contexts/FirebaseContext';
import { isFunction } from 'lodash';

const slice = createSlice({
  name: 'category',
  initialState: {
    list: [],
    loading: false,
    isInitialized: false
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.isInitialized = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error(action.payload);
    },

    gotCategories(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    }
  }
});

const { startLoading, hasError, gotCategories } = slice.actions;

export const selectCategories = state => state.categories;

export default slice.reducer;

export function getCategories(callback = null) {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const doc = await firestore.collection('categories').doc('list').get();

      const list = doc.data()?.data ||[];

      dispatch(gotCategories(list));

      isFunction(callback) && callback();

    } catch (error) {
      dispatch(hasError(error));
    }
  };
}