import {useCallback, useEffect, useRef} from "react";
import {isFunction} from "lodash";

export default function useCallbackGuard() {
    const isMountedRef = useRef(true);
    const isMounted = useCallback(() => isMountedRef.current, []);

    useEffect(() => {
        return () => void (isMountedRef.current = false);
    }, []);

    const callbackGuard = callback => isMounted() && isFunction(callback) && callback()

    return {isMounted, callbackGuard};
}