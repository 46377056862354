import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/messaging';
import { firebaseConfig } from '../config';
import { store } from '../redux/store';
import { createFirestoreInstance } from 'redux-firestore'; // <- needed if using firestore
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
// ----------------------------------------------------------------------

const ADMIN_EMAILS = ['demo@minimals.cc'];


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();
const storage = firebase.storage();
const messaging = firebase.messaging();
const publicKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;


const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  loginWithFaceBook: () => Promise.resolve(),
  loginWithTwitter: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [profile, setProfile] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const auth = { ...state.user };

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          const docRef = firebase.firestore().collection('users').doc(user.uid);
          docRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                setProfile(doc.data());

              }
            })
            .catch((error) => {
              console.error(error);
            });

          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: true, user }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null }
          });
        }
      }),
    [dispatch]
  );



  useEffect(() => {
    if (auth?.uid) {
      messaging.getToken().then((token) =>{
        firestore.collection('users').doc(auth?.uid).update({token})
      }).catch(error =>{
        Notification.requestPermission().then(access =>{
          if(access === "granted"){
              messaging.getToken().then((token) =>{
                firestore.collection('users').doc(auth?.uid).update({token})
              })
          }
        })
      })
    }
  }, [auth?.uid]);

  const login = (email, password) => firebase.auth().signInWithEmailAndPassword(email, password);

  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithFaceBook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };


  const register = (email, password, firstName, lastName, phoneNumber, role) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        firebase
          .firestore()
          .collection('users')
          .doc(res.user.uid)
          .set({
            uid: res.user.uid,
            email,
            displayName: `${firstName} ${lastName}`,
            role,
            phoneNumber
          });
      });

  const logout = async () => {
    await firebase.auth().signOut();
  };

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };



  const rrfProps = {
    firebase,
    config: {
      userProfile: 'users',
      useFirestoreForProfile: true
    },
    dispatch: store.dispatch,
    createFirestoreInstance // <- needed if using firestore
  };

  return (
    <ReactReduxFirebaseProvider {...rrfProps}>
      <AuthContext.Provider
        value={{
          ...state,
          method: 'firebase',
          user: {
            id: auth.uid,
            email: auth.email,
            photoURL: auth.photoURL || profile?.photoURL,
            displayName: auth.displayName || profile?.displayName,
            role: ADMIN_EMAILS.includes(auth.email) ? 'admin' : 'user',
            phoneNumber: auth.phoneNumber || profile?.phoneNumber || '',
            country: profile?.country || '',
            address: profile?.address || '',
            state: profile?.state || '',
            city: profile?.city || '',
            zipCode: profile?.zipCode || '',
            about: profile?.about || '',
            isPublic: profile?.isPublic || false
          },
          login,
          register,
          loginWithGoogle,
          loginWithFaceBook,
          loginWithTwitter,
          logout,
          resetPassword
        }}
      >
        {children}
      </AuthContext.Provider>
    </ReactReduxFirebaseProvider>
  );
}


export { AuthContext, AuthProvider, firestore, storage, firebase, messaging, publicKey };
