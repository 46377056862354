import { styled } from '@mui/material/styles';
import { Box, Stack, IconButton, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OrderCustomList from './../../components/_dashboard/e-commerce/orders/OrderCustomList';
import DialogAnimate from './../../components/animate/DialogAnimate';
import Scrollbar from './../../components/Scrollbar';

// ----------------------------------------------------------------------

const DialogStyle = styled(DialogAnimate)(({ theme }) => ({
  '& .MuiDialog-paper': {
    margin: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(100% - 48px)',
      maxHeight: 'calc(100% - 48px)'
    }
  }
}));

// ----------------------------------------------------------------------

export default function EcommerceOrdersHistorics({ onClose, ...other }) {
 

  return (
    <DialogStyle fullScreen {...other}>

      <DialogTitle>
        <Stack direction="row" justifyContent="flex-end">
          <IconButton color="error" sx={{ border: '1px solid red'}} onClick={onClose}>
              <CloseIcon  />
          </IconButton>
        </Stack>
      </DialogTitle>

        <DialogContent dividers>
          <Box sx={{m : 3}}>
            <Scrollbar>
              <OrderCustomList />
            </Scrollbar>
          </Box>
        </DialogContent>
    </DialogStyle>
  );
}
