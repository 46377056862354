import { sum, map, filter, uniqBy, reject, isFunction } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import { firestore } from '../../contexts/FirebaseContext';
import { isFile } from '../../utils/type_check';
import { multipleFilesSave } from './document';
import { v4 as orderCode } from 'uuid';
import axios from 'axios';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  products: [],
  baseProducts: [],
  createdAt: null,
  arrivalId: null,
  product: null,
  sortBy: null,
  categories: [],
  filters: {
    gender: [],
    category: 'Tout',
    colors: [],
    priceRange: '',
    rating: ''
  },
  checkout: {
    activeStep: 0,
    commandNum: '',
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null
  }
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    //GET CATEGORIES
    getProductCategories(state, action) {
      state.categories = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
      state.baseProducts = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // DELETE PRODUCT
    deleteProduct(state, action) {
      state.products = reject(state.products, { id: action.payload });
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    searchProducts(state, action) {
      state.isLoading = true;
      const { search } = action.payload;

      if (search.trim() !== '') {
        let results = [];
        const searchTab = search.trim().toUpperCase().split(' ');

        searchTab.forEach((text) => {
          const result = state.baseProducts.filter((prod) => prod.ProductName.toUpperCase().includes(text));
          results.push(...result);
        });

        let alreadyFind = [];

        results.forEach((res) => {
          const exist = alreadyFind.find(it => it.ProductId === res.ProductId);
          if (exist) {
            return exist.Priority += 1;
          }
          res.Priority = 1;
          alreadyFind.push(res);
        });

        let responses = alreadyFind.sort((a, b) => b.Priority - a.Priority);

        state.products = responses;

      } else {
        state.products = state.baseProducts;
      }
      state.isLoading = false;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(cart.map((product) => product.price * product.quantity));
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = map(state.checkout.cart, (_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = filter(state.checkout.cart, (item) => item.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep = 3;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    setCommandeNum(state, action) {
      state.checkout.commandNum = action.payload;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    },

    applyShippingDate(state, action) {
      state.createdAt = action.payload;
    },

    applyArrivalId(state, action) {
      state.arrivalId = action.payload;
    }

  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  deleteProduct,
  createBilling,
  applyShipping,
  applyDiscount,
  filterProducts,
  sortByProducts,
  searchProducts,
  increaseQuantity,
  decreaseQuantity
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const arrivalsDoc = await firestore.collection('arrivals').orderBy('createdAt', 'asc')
        .limitToLast(1).get();

      let items = [];

      if (!arrivalsDoc.empty) {
        const docId = arrivalsDoc.docs[0].id;
        const response = await firestore.collection('arrivals').doc(docId).collection('list').get();
        response.docs.forEach((product) => {
          items.push({ id: product.id, ...product.data() });
        });

        dispatch(slice.actions.applyShippingDate(arrivalsDoc.docs[0].data()['createdAt']?.toDate()));
        dispatch(slice.actions.applyArrivalId(docId));

      }

      dispatch(slice.actions.getProductsSuccess(items));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(product = null, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (product) {
        dispatch(slice.actions.getProductSuccess(product));
      } else {
        const arrivalsDoc = await firestore.collection('arrivals').orderBy('createdAt', 'desc')
          .limitToLast(1).get();
        const docId = arrivalsDoc.docs[0].id;

        const doc = await firestore.collection('arrivals').doc(docId).collection('list').where('ProductId', '==', id).get();
        const item = doc.docs[0].data();

        dispatch(slice.actions.getProductSuccess({ id: item.productId, ...item }));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateProduct(product, callback = null) {
  return async (dispatch, getState) => {
    try {

      dispatch(slice.actions.startLoading());

      const arrivalId = getState().product.arrivalId;

      const { id, Images, ...rest } = product;

      const toUpload = Images.filter(file => isFile(file));

      await multipleFilesSave(toUpload, async (images) => {

          const imagesData = Images?.map(image => {
            const exist = images.find(one => one?.name === image?.name);
            return exist || image;
          });

          const [cover, ...allImages] = imagesData?.map(one => one?.preview || one?.url);

          const arrivalsListRef = firestore.collection('arrivals').doc(arrivalId)
            .collection('list');

          const recover = await arrivalsListRef.where('ProductId', '==', rest.ProductId)
            .limit(2).get();

          if (!recover.empty) {

            const batch = firestore.batch();

            let docId = null;

            if (recover.docs.length === 1) {
              docId = recover.docs[0].id;
            } else if (recover.docs.length === 2) {

              const other = recover.docs.filter(one => one.id !== rest.ProductId);
              docId = other[0].id;

              batch.delete(arrivalsListRef.doc(rest.ProductId));
            }

            // console.group()
            // console.log(rest.ProductId)
            // console.log(recover.docs.map(one=>one.id))
            // console.log(docId)
            // console.groupEnd()

            const arrivalRef = arrivalsListRef.doc(docId);

            const itemRef = firestore.collection('items').doc(rest.ProductId);

            batch.update(arrivalRef, {
              ...rest,
              Images: allImages,
              ProductImageUrl: cover || null
            });

            batch.set(itemRef, {
              Name: rest?.ProductName,
              Description: rest?.Description,
              Images: allImages,
              ImageUrl: cover || null,
              Categorie: rest?.Categorie
            }, { merge: true });

            await batch.commit();

          }


          isFunction(callback) && callback();

        }
      );

    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategories() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const catDoc = await firestore.collection('categories').doc('list').get();
      const catgories = ['Tout'];

      catDoc.data()?.data.forEach((cat) => {
        catgories.push(cat.label);
      });

      dispatch(slice.actions.getProductCategories(catgories));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function makeCommande(user, item = null, callback = null) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {

      let cart = [];
      if (item) {
        cart = [item];
      } else {
        cart = getState().product.checkout.cart;
      }

      const comandNum = orderCode();
      const order = {
        cart:cart.map(one=>{
          const {price,...rest}=one;
          return {...rest}
        }),
        userId: user.id,
        status: 'En Attente',
        orderNum: comandNum,
        email: user.email,
        username: user.displayName,
        phoneNumber: user?.phoneNumber,
        createdAt: new Date()
      };

      await firestore.collection('arrival_command').add(order);
      dispatch(slice.actions.setCommandeNum(comandNum));

      dispatch(notifyOrderCreation(order));

      if (callback) callback();

    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

const notifyOrderCreation = (order, callback = null) => {
  return async () => {
    try {
      await axios.post('https://us-central1-dollarstore-eab75.cloudfunctions.net/sendNotification', { command: order });
      console.log('---make-order---');
    } catch (e) {
      console.log(e);
    }
  };
};