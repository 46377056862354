import { Button, Grid,  } from '@mui/material';
import { useRef, useState } from 'react';
import useToggle from '../../../../hooks/useToggle';
import { isFunction } from 'lodash';
import DeletePopup from '../../../DeletePopup';
import { useDispatch } from '../../../../redux/store';
import { fetchCustomOrders, updateOrderState } from '../../../../redux/slices/order';
import { ORDER_STATE } from '../../../../constants';
import useCallbackGuard from '../../../../hooks/useCallbackGuard';
import { useSnackbar } from 'notistack';
import useAuth from '../../../../hooks/useAuth';

export default function OrdersMoreOptionsClients({ id, state, onOpenDetails }) {
  const ref = useRef();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const { callbackGuard } = useCallbackGuard();
  const { enqueueSnackbar } = useSnackbar();

  const { open, handleOpen, handleClose } = useToggle();

  const handleOpenDetail = () => isFunction(onOpenDetails) && onOpenDetails(id);

  const handleForsakeOrder = () => {
    setPending(true);
    dispatch(updateOrderState(id, ORDER_STATE.FORSAKEN, () => {
      callbackGuard(() => setPending(false));
      handleClose();
      enqueueSnackbar('Votre commande a ete bien annule');
      dispatch(fetchCustomOrders(user.id));
    }));
  };

  return (
    <Grid container spacing={2} justifyItems={'center'}>

      <Grid item xs={6} style={{display:'grid',placeItems:'center'}}>
        <Button variant={'contained'} onClick={handleOpenDetail} >
          Details
        </Button>
      </Grid>

      {
        state !== ORDER_STATE.FORSAKEN && (
          <Grid item xs={6} >
            <Button color={'error'} onClick={handleOpen} ref={ref} variant={'outlined'}>
              Annuler la commande
            </Button>
          </Grid>
        )
      }

      <DeletePopup
        anchorRef={ref}
        open={open}
        onClose={handleClose}
        text={'Vous êtes sur le point d\'annuler la commande, Continuer ?'}
        onDelete={handleForsakeOrder}
        pending={pending}
      />

    </Grid>
  );
}