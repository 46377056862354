import {
  Stack,
  Alert,
  Button,
  CircularProgress
} from '@mui/material';
import PropTypes from 'prop-types';
import MenuPopover from './MenuPopover';
import NotistackProvider from './NotistackProvider';


DeletePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  pending: PropTypes.bool.isRequired
};

export default function DeletePopup({ open, onClose, onDelete, pending, anchorRef, text , element = '' }) {
  return (
    <MenuPopover sx={{ width: 500 }} open={open} onClose={onClose} anchorEl={anchorRef.current}>
      <NotistackProvider>
        <Alert severity='error'>
          {text || `Vous êtes sur le point de supprimer ${element}, Continuer ?`}
        </Alert>
      </NotistackProvider>
      <Stack direction='row' spacing={3} sx={{ p: 1, float: 'right' }}>
        <Button color='info' disabled={pending} onClick={onClose}> Non, je suis pas sur</Button>
        <Button color='error' disabled={pending} onClick={onDelete}>
          {pending ? <CircularProgress color='primary' size={20} /> : ' Oui, annuler'}
        </Button>
      </Stack>
    </MenuPopover>
  );
}
